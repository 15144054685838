import React from 'react';
import styles from "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import {
  MainContainer,
  ChatContainer,
  MessageList,
  Message,
  MessageInput,
} from "@chatscope/chat-ui-kit-react";

const ChatUI = ({ conversation }) => {
    return (
        <div style={{ position: "relative", height: "100%" }}>
          <MainContainer>
            <ChatContainer>
              <MessageList>
                {conversation.map((msg, index) => (
                  <Message
                    key={index}
                    model={{
                      message: msg.message,
                      sentTime: "just now", // Aquí podrías agregar una lógica para la hora real del mensaje
                      sender: msg.sender,
                    }}
                  />
                ))}
              </MessageList>
              {/* Si necesitas un input de mensaje, puedes incluirlo aquí */}
              {/* <MessageInput placeholder="Type message here" /> */}
            </ChatContainer>
          </MainContainer>
        </div>
      );
    };

export default ChatUI;
