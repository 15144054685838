import React, { useState, useEffect } from 'react';
import { Avatar, Button, CssBaseline, TextField, Box, Typography, Container, CircularProgress, LinearProgress } from '@mui/material';
import MicIcon from '@mui/icons-material/Mic';
import TextIcon from '@mui/icons-material/Abc';
import { OpenAI } from "openai";
import ChatUI from './ChatUI';
import Grid from '@mui/material/Unstable_Grid2'; 



const openaiApiKey = process.env.REACT_APP_OPENAI_API_KEY;
const elevenLabsApiKey = process.env.REACT_APP_ELEVENLABS_API_KEY;
// Asistente para Pedidos restaurante
//const assistantIdToUse = "asst_dUHm5V0u9B0RzuLfQFkjmZfM";
// Asistente de Test de negocios
const assistantIdToUse = "asst_haczn3MvvE7PmE6rPQ4o2gtD";
// Voz femenina
const elevenLabsVoiceID = "EXAVITQu4vr4xnSDxMaL";


const openai = new OpenAI({
  apiKey: openaiApiKey, 
  dangerouslyAllowBrowser: true
});

const emptyThread = await openai.beta.threads.create();
const threadByUser = emptyThread.id;


const VoiceChat = () => {
  const [conversation, setConversation] = useState([]);
  const speechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
  const recognition = new speechRecognition();
  const [conversationStage, setConversationStage] = useState('default'); 
  const [audioData, setAudioData] = useState(null); 
  const [isRecording, setIsRecording] = useState(false);

  useEffect(() => {
    // Solicitar acceso al micrófono
    navigator.mediaDevices.getUserMedia({ audio: true })
      .then(stream => {
        // Aquí puedes hacer algo con el stream, por ejemplo, guardar la referencia para usarla más tarde
      })
      .catch(error => {
        console.error('Error al obtener acceso al micrófono:', error);
      });
  }, []);

  recognition.onresult = async (event) => {
    const transcript = event.results[0][0].transcript;
    updateConversation({ sender: 'User', message: transcript });
    setConversationStage('processing'); 
    const assistantResponse = await sendMessageToAssistant(transcript);
    updateConversation({ 
      sender: 'Assistant', 
      message: assistantResponse.text,
      audioData: assistantResponse.audioData
    });
    setAudioData(assistantResponse.audioData); 
    const success = await handlePlayAudio(assistantResponse.text);
    if (!success) {
      setConversationStage('playAudio');
    }
  };

  const handleOpenAIRequest = async (userMessage) => {
      try {
        const myThreadMessage = await openai.beta.threads.messages.create(
          threadByUser, // Usar el ID del thread almacenado para este usuario
          {
            role: "user",
            content: userMessage,
          }
        );
        console.log("Este es el objeto del mensaje: ", myThreadMessage, "\n");
    
        // Ejecutar el Asistente
        const myRun = await openai.beta.threads.runs.create(
          threadByUser, // Usar el ID del thread almacenado para este usuario
          {
            assistant_id: assistantIdToUse
          }
        );
        console.log("Este es el objeto run: ", myRun, "\n");
    
        // Recuperar periódicamente el Run para verificar su estado
          while (myRun.status !== "completed") {
             let keepRetrievingRun = await openai.beta.threads.runs.retrieve(
              threadByUser, // Usar el ID del thread almacenado para este usuario
              myRun.id
            );
    
            console.log(`Estado del run: ${keepRetrievingRun.status}`);
    
            if (keepRetrievingRun.status === "completed") {
              console.log("\n");
              break;
            }
          }
    
        // Recuperar los mensajes agregados por el Asistente al Thread
        const allMessages = await openai.beta.threads.messages.list(
          threadByUser // Usar el ID del thread almacenado para este usuario
        );
        const assistantResponse = allMessages.data[0].content[0].text.value;
  
        // Enviar la respuesta del asistente
        return assistantResponse;
  } catch (error) {
    console.error("Error:", error);
    return 'Error al comunicarse con el asistente.';
  }
}
  const updateConversation = (messageObj) => {
    setConversation(prev => [...prev, messageObj]);
  };

  const handleTextResponse = async () => {
    const responseTextField = document.getElementById('answer'); // Obtener el elemento de entrada de texto
  const message = responseTextField.value; // Obtener el valor del texto ingresado por el usuario

  if (message.trim() === '') {
    // Evitar enviar un mensaje vacío
    return;
  }
    // Actualizar la conversación con la respuesta del asistente
    updateConversation({
      sender: 'User',
      message: message,
    });
  setConversationStage('processing');
  // Enviar el mensaje al asistente y esperar la respuesta
  const assistantResponse = await handleOpenAIRequest(message);

  if (assistantResponse) {
    updateConversation({
      sender: 'Assistant',
      message: assistantResponse,
    });
    const success = await handlePlayAudio(assistantResponse);
    if (!success) {
      setConversationStage('playAudio');
    } else {
      setConversationStage('default');
    }
  }

  // Limpiar el campo de respuesta después de enviar el mensaje
  responseTextField.value = '';
  };

  const sendMessageToAssistant = async (message) => {
    try {
      // Obtener la respuesta del asistente utilizando la función handleOpenAIRequest
      const assistantResponse = await handleOpenAIRequest(message);
  
      if (assistantResponse) {
        // Si hay una respuesta, devolver la respuesta y los datos de audio (si están disponibles)
        return { text: assistantResponse, audioData: null }; // Asumiendo que no hay datos de audio disponibles
      } else {
        // Si no hay respuesta, devolver un mensaje predeterminado
        return { text: 'No se recibió respuesta del asistente.', audioData: null };
      }
    } catch (error) {
      console.error('Error communicating with the assistant:', error);
      return { text: 'Hubo un error al comunicarse con el asistente.', audioData: null };
    }
  };

  const fetchAudioFromElevenLabs = async (assistantResponse) => {
    const apiRequestOptions = {
      method: 'POST',
      headers: {
        'Accept': 'audio/mpeg',
        'Content-Type': 'application/json',
        'XI-API-KEY': elevenLabsApiKey,
      },
      body: JSON.stringify({
        text: assistantResponse,
        model_id: "eleven_multilingual_v1"
      })
    };
  
    try {
      const response = await fetch(`https://api.elevenlabs.io/v1/text-to-speech/${elevenLabsVoiceID}`, apiRequestOptions);
      const arrayBuffer = await response.arrayBuffer();
      const audioBase64 = btoa(String.fromCharCode(...new Uint8Array(arrayBuffer)));
      return audioBase64;
    } catch (error) {
      console.error('Error fetching audio from ElevenLabs:', error);
      return null;
    }
  };

  // Función para manejar la reproducción de audio
  const handlePlayAudio = async (text) => {
    const audioBase64 = await fetchAudioFromElevenLabs(text);
    if (audioBase64) {
      const audio = new Audio(`data:audio/mpeg;base64,${audioBase64}`);
      audio.play();
      audio.onended = () => setConversationStage('default');
      setConversationStage('assistantSpeaking');
      return true;
  } else {
    return false;
    }
  };

  const getLastAssistantMessage = () => {
    const lastMessage = conversation.slice().reverse().find(msg => msg.sender === 'Assistant');
    return lastMessage ? lastMessage.message : '';
  };

  const handleSkipAudio = () => {
    setConversationStage('default');
  };

  const handleStartRecording = () => {
    recognition.start();
    setIsRecording(true);
    setConversationStage('userSpeaking');
  };

  const handleStopRecording = () => {
    recognition.stop();
    setIsRecording(false);
  };

  return (
    <Grid container>
    <Grid xs={8}>
    <Container component="main" maxWidth="xs">
    <CssBaseline />
      {/* Seccion para iniciar a hablar */}
      {conversationStage === 'default' && (
        <Box>
        <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
        </Avatar>
        <Typography align="center" component="h1" variant="h2" mt={5} mb={5}>
        Toca el botón para hablar
        </Typography>
        <Button variant="contained" size="large" startIcon={<MicIcon />} onClick={handleStartRecording}>Hablar</Button>
      </Box>
        <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography align="center" component="h1" variant="h2" mt={5} mb={5}>
       ó Escribe tu respuesta
        </Typography>
        <TextField 
          id="answer"
          label="Respuesta"
          multiline
          margin="normal"
          maxRows={4} placeholder="Mi respuesta es..." />
        <Button variant="contained" size="large" startIcon={<TextIcon />} onClick={handleTextResponse}>Mandar Mensaje Escrito</Button>
      </Box>
      </Box>
      )}

      {/* Seccion para mientras el usuario habla */}
      {conversationStage === 'userSpeaking' && (
        <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
        </Avatar>
        <Typography align="center" component="h1" variant="h2" mt={5} mb={5}>
        Escuchando...
        </Typography>
        <Button variant="contained" size="large" startIcon={<MicIcon />} onClick={handleStopRecording}>Detener</Button>
      </Box>
      )}

      {/* Seccion para mientras se procesa la respuesta */}
      {conversationStage === 'processing' && (
        <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
        </Avatar>
        <Typography align="center" component="h1" variant="h2" mt={5} mb={5}>
        Pensando...
        </Typography>
        <CircularProgress />
      </Box>
      )}
      {/* Seccion para reproducir audio de respuesta */}
      {conversationStage === 'playAudio' && (
        <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
        </Avatar>
        <Typography align="center" component="h1" variant="h2" mt={5} mb={5}>
        Escuhar respuesta
        </Typography>
        <Button variant="contained" size="large" startIcon={<MicIcon />} onClick={() => handlePlayAudio(getLastAssistantMessage())}>Escuchar Respuesta</Button>
        <Button variant="contained" size="small" onClick={() => handleSkipAudio()}>Saltar</Button>
      </Box>
      )}

      {/* Seccion para mientras habla el asistente */}
      {conversationStage === 'assistantSpeaking' && (
        <Box
        sx={{
          marginTop: 8,
          marginBottom: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
        </Avatar>
        <Typography align="center" component="h1" variant="h2" mt={5} mb={5}>
        Hablando...
        </Typography>
        <LinearProgress />
      </Box>
      )}

      {/* Sección de conversaciones */}
      </Container>
      </Grid>

    <Grid xs={4}>
      <ChatUI conversation={conversation} />
      </Grid>
    </Grid>
  );
};

export default VoiceChat;