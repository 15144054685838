import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes
} from 'react-router-dom';
import VoiceChat from './components/VoiceChat';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<VoiceChat />} />
        {/* Puedes añadir más rutas aquí */}
      </Routes>
    </Router>
  );
}

export default App;
